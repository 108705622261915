import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../../store/reducers/rootReducer";
import { parseJwt } from "../../shared/helpers/decodeJWT/decodeJWT";
import { useEffect, useState } from "react";
import { fetchGetJsonData } from "../../../services/services";
import { DropdownButton } from "../../shared/DropdownButton/DropdownButton";
import { editAudienceAdminReducer } from "../../../store/reducers/AudienceAdminReducer/audienceAdminReducer";

type BuyersData = {
  id: string | null;
  customerId: string | null;
  name: string;
}

type Props = {
  setIsOverlayLoading: (overlayLoading: boolean) => void;
}

export const AudienceAdmin = ({ setIsOverlayLoading }: Props) => {
  // if there is a customerID we need to pass it in the function
  const { name, customerId } = useSelector((state: RootState) => state.audienceAdminReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const [buyersData, setBuyersData] = useState<BuyersData[]>();
  const dispatch = useDispatch();

  const audienceAdmin = parseJwt(token);
  const isAudienceAdmin = audienceAdmin[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/audienceAdmin`] as unknown as boolean;

  useEffect(() => {
    if (isAudienceAdmin) {
      // change to the new fetch funtion fetchGetJsonData => from 'services.ts' = make it easier bc there is a type
      fetchGetJsonData<BuyersData[]>({ url: "au/a/integrationpartners/buyers", token: token, baseUrl: "audience", customerId: customerId })
        .then((res) => {
          const wbuyersData = [{ id: null, customerId: null, name: 'Walr' }, ...res];
          setBuyersData(wbuyersData);
        }).catch((error) => {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } })
        })
    }
  }, [dispatch, token, customerId, isAudienceAdmin]);

  const onItemSelect = (e: TODO) => {
    if ((customerId === e.item.customerId) || e.item.name === 'Walr') {
      dispatch(editAudienceAdminReducer({ name: 'Walr', customerId: null }))
      setIsOverlayLoading(true);
    } else {
      setIsOverlayLoading(true);
      dispatch(editAudienceAdminReducer({ name: e.item.name, customerId: e.item.customerId }))
    }
  }

  return isAudienceAdmin ?
    <DropdownButton
      items={buyersData}
      textField="name"
      className="btn-shadow icon-r overview-input"
      text={name}
      onItemClick={(e) => onItemSelect(e)}
    />
    :
    null
}
