import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Dialog } from '@progress/kendo-react-dialogs'
import { Tooltip } from '@progress/kendo-react-tooltip'

import { Icon } from '../../shared/Icon/Icon'
import type { RootState } from '../../../store/reducers/rootReducer'
import type { SuppliersResponse, ConvertedSupplierData } from '../../../interfaces/gateKeeperInterfaces/gateKeeperInterfaces'
import { fetchGetJsonData } from '../../../services/services'

// const HEADER_TABLE_th = ["Group name"] as const
const SUPPLIERS_TABLE_th = ["Name", "Clicks", "Blocked entry", "Client entry",
  "Complete", "Screen out", "Quota fails",
  "Drop outs", "Median LOI", "Survey IR",
  "Conversion"] as const

type Props = {
  onHide: (show: boolean) => void;
  type: "sample" | "audience" | "batch";
  sample: { name: string, id: string, projectId: string };
  audience?: { name?: string, id?: string };
  batch?: { id?: string, displayName?: string };
}

export const GKstatisticsModal = ({ onHide, type, sample, audience, batch }: Props) => {
  const [suppliersData, setSuppliersData] = useState<ConvertedSupplierData[] | null>(null)
  const [inputValueType, setInputValueType] = useState<string>("#");
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customerId } = useSelector((state: RootState) => state.audienceAdminReducer);

  const calculatePercent = (click: number, value: number): number => {
    return Math.floor(value / click * 100)
  }
  const calculateLoi = (loi: number | undefined, averageLOI: number): string => {
    if (loi) {
      const loiVariance = Math.floor(averageLOI - loi)
      const returnPositiveOrNegativeSign: string = loiVariance > 0 ? "+" : ""
      return `(${returnPositiveOrNegativeSign}${loiVariance})`
    }
    return ""
  }

  const formatNumberDecimals = (number: number): string => {
    return new Intl.NumberFormat().format(number)
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const convertObj = useCallback((response: SuppliersResponse): ConvertedSupplierData => {
    return {
      displayName: response.displayName,
      platform: response.platformName,
      clicks: response.clicks,
      blockEntry: { number: response.blockedEntries, percent: calculatePercent(response.clicks, response.blockedEntries) },
      clientEntry: { number: response.clientEntries, percent: calculatePercent(response.clicks, response.clientEntries) },
      complete: { number: response.completes, percent: calculatePercent(response.clicks, response.completes) },
      screenOut: { number: response.screenOuts, percent: calculatePercent(response.clicks, response.screenOuts) },
      quotaFails: { number: response.quotaOuts, percent: calculatePercent(response.clicks, response.quotaOuts) },
      dropOuts: { number: response.dropOffs, percent: calculatePercent(response.clicks, response.dropOffs) },
      averageLOI: response.averageLOI,
      loi: { loi: response.loi, loiVariance: Math.floor(response.averageLOI - response.loi) },
      surveyIr: response.surveyIR,
      overallIr: response.overallIR,
    };
  }, [])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const calcutateTotal = useCallback((res: SuppliersResponse[]): ConvertedSupplierData => {

    const sumClicks = res.reduce((sum, obj) => sum + obj.clicks, 0);
    const sumBlockEntry = res.reduce((sum, obj) => sum + obj.blockedEntries, 0);
    const sumClientEntries = res.reduce((sum, obj) => sum + obj.clientEntries, 0);
    const sumCompletes = res.reduce((sum, obj) => sum + obj.completes, 0);
    const sumScreenOut = res.reduce((sum, obj) => sum + obj.screenOuts, 0);
    const sumQuotaOuts = res.reduce((sum, obj) => sum + obj.quotaOuts, 0);
    const sumDropOffs = res.reduce((sum, obj) => sum + obj.dropOffs, 0);
    const sumAverageLOI = res.reduce((sum, obj) => sum + Math.floor(obj.averageLOI), 0);
    const sumLOI = res.reduce((sum, obj) => sum + Math.floor(obj.loi), 0);
    const sumLoiVariance = sumAverageLOI - res.reduce((sum, obj) => sum + obj.loi, 0);

    return {
      platform: "Total",
      clicks: sumClicks,
      blockEntry: { number: sumBlockEntry, percent: calculatePercent(sumClicks, sumBlockEntry) },
      clientEntry: { number: sumClientEntries, percent: calculatePercent(sumClicks, sumClientEntries) },
      complete: { number: sumCompletes, percent: calculatePercent(sumClicks, sumCompletes) },
      screenOut: { number: sumScreenOut, percent: calculatePercent(sumClicks, sumScreenOut) },
      quotaFails: { number: sumQuotaOuts, percent: calculatePercent(sumClicks, sumQuotaOuts) },
      dropOuts: { number: sumDropOffs, percent: calculatePercent(sumClicks, sumDropOffs) },
      averageLOI: sumAverageLOI,
      loi: { loi: sumLOI, loiVariance: sumLoiVariance },
      surveyIr: sumCompletes / (sumCompletes + sumScreenOut) * 100,
      overallIr: (sumCompletes / sumClicks) * 100,
    }
  }, []);

  const statsDisplayName = () => {
    if (type === "batch" && audience && batch) {
      return batch.displayName;
    }

    if (type === "audience" && audience) {
      return audience.name;
    }

    if (type === "sample") {
      return sample.name;
    }
  }

  const fetchUrl = { "sample": `au/a/projects/${sample.projectId}/samples/${sample.id}/stats`, "audience": `au/a/projects/${sample.projectId}/samples/${sample.id}/audiences/${audience?.id}/stats`, "batch": `au/a/projects/${sample.projectId}/samples/${sample.id}/audiences/${audience?.id}/batches/${batch?.id}/stats` };
  const tableDisplayName = statsDisplayName();

  useEffect(() => {
    setIsLoading(true)
    fetchGetJsonData<SuppliersResponse[]>({ url: fetchUrl[type], token: token, baseUrl: "audience", customerId: customerId })
      .then((res) => {
        const dataArray = res.map(convertObj)
        if (dataArray) {
          setSuppliersData(dataArray)

          const totalObject = calcutateTotal(res)
          setSuppliersData([totalObject, ...dataArray])
          setIsLoading(false)
        }
      }).catch((error) => {
        setIsLoading(false)
        setErrorMessage(error)
      })
  }, [token, calcutateTotal, convertObj, fetchUrl[type], type, customerId])

  const handleInputValueType = (inputValueType: string) => {
    inputValueType === "%" ? setInputValueType("#") : setInputValueType("%")
  }

  return (
    <Dialog width={"80%"} className="supplier-stats" title="Stats" onClose={() => onHide(false)}>
      {tableDisplayName ?
        <>
          {/* <div className='d-flex stronger p-4' style={{ fontSize: '1.125rem' }}>{tableDisplayName}</div> */}
          <div className='d-flex justify-content-between p-4' >
            <table className="header-stats-table table-borderless m-0 p-0">
              <thead>
                <tr>
                  {/* {HEADER_TABLE_th.map((item: string) => (<th key={item} className='medium normal text-muted'>{item}</th>))} */}
                </tr>
              </thead>
              <tbody >
                <tr>
                  <td className='regular strong'>{tableDisplayName}</td>
                </tr>
              </tbody>
            </table>
            {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
            <div className="cs-input-switch h-32" onClick={(e) => { e.stopPropagation(); handleInputValueType(inputValueType) }}>
              <div className={`cs-input-item d-flex justify-content-center ${inputValueType === "%" ? "bg-white" : ""}`}>%</div>
              <div className={`cs-input-item d-flex justify-content-center ${inputValueType === "#" ? "bg-white" : ""}`}>#</div>
            </div>
          </div>
          {/* TABLE STATS DATA */}
          {suppliersData ?
            <table className="table stats-content-table">
              <thead>
                <tr>
                  {SUPPLIERS_TABLE_th.map((item: string) => (<th className={"text-muted small normal text-nowrap"} key={item} >
                    <div className='d-flex align-items-end justify-content-start'>
                      {item}
                      {item === "Survey IR" &&
                        <Tooltip className='suppliers-header-tooltip' anchorElement="target" position="top">
                          <span className='icon cursor-pointer' title={"Survey IR = Completes / Completes + Screen out"}><Icon style={{ opacity: "0.5" }} type="info-tooltip" className='pe-none' /></span>
                        </Tooltip>
                      }
                      {item === "Overall IR" &&
                        <Tooltip className='suppliers-header-tooltip' anchorElement="target" position="top">
                          <span className='icon cursor-pointer ' title={"Overall Conversion = Completes / Clicks"}><Icon style={{ opacity: "0.5" }} type="info-tooltip" className='pe-none' /></span>
                        </Tooltip>}
                    </div>
                  </th>))}
                </tr>
              </thead>
              <tbody>
                {suppliersData?.length !== 0 &&
                  suppliersData?.map((item: ConvertedSupplierData) => (
                    <tr key={crypto.randomUUID()} className='w-100 text-primary'>
                      <td>
                        <p className='m-0 text-truncate medium' style={{ maxWidth: "160px" }}>
                          {item.platform ? item.platform : item.displayName}
                        </p>
                      </td>
                      <td>{formatNumberDecimals(item.clicks)}</td>
                      <td>
                        <div className="badge">{inputValueType === "#" ? formatNumberDecimals(item.blockEntry.number) : `${item.blockEntry.percent}%`}</div>
                      </td>
                      <td>{inputValueType === "#" ? `${formatNumberDecimals(item.clientEntry.number)}` : `${item.clientEntry.percent}%`}</td>
                      <td>{inputValueType === "#" ? `${formatNumberDecimals(item.complete.number)}` : `${item.complete.percent}%`}</td>
                      <td>{inputValueType === "#" ? `${formatNumberDecimals(item.screenOut.number)}` : `${item.screenOut.percent}%`}</td>
                      <td>{inputValueType === "#" ? `${formatNumberDecimals(item.quotaFails.number)}` : `${item.quotaFails.percent}%`}</td>
                      <td>{inputValueType === "#" ? `${formatNumberDecimals(item.dropOuts.number)}` : `${item.dropOuts.percent}%`}</td>
                      <td>{`${item.averageLOI ? Math.floor(item.averageLOI) : "/"} ${calculateLoi(item.loi?.loi, item.averageLOI)}`}</td>
                      <td>{Math.floor(item.surveyIr)}%</td>
                      <td>{Math.floor(item.overallIr)}%</td>
                      <td />
                    </tr>
                  ))
                }
              </tbody>
            </table>
            :
            <div className='stats-content-table d-flex justify-content-center align-items-center p-10 text-muted'>
              {isLoading && <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" />}
              {errorMessage}
            </div>
          }
        </>
        :
        <span />
      }
    </Dialog >
  )
}