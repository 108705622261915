import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

type AudienceAdmin = {
    customerId: string | null,
    name: string | null
}

const initialState: AudienceAdmin = {
    customerId: null,
    name: 'Walr'
}

const audienceAdminReducerSlice = createSlice({
  name: 'audiencesAdmin',
  initialState,
  reducers: {
    editAudienceAdminReducer(_state, action: PayloadAction<AudienceAdmin>) {
      const updatedState = action.payload;
      return updatedState
    }
  }
})

export const {
    editAudienceAdminReducer
} = audienceAdminReducerSlice.actions
export default audienceAdminReducerSlice.reducer
